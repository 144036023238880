.Full_Page_Img {
  background-image: url("../Images/Full_Page_Img.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 0;
  margin-top: 0;
}

@media (max-width: 1400px) and (min-width: 768px) {
  .Full_Page_Img {
    height: 100vh;
  }
}

.Sign_Up_Name {
  font-size: 2rem;
  font-weight: bold;
}

.Register_Icons {
  height: 1.4rem;
  width: 2.9rem;
}

.Sign_Up_Button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  background-color: #b8529e;
  color: white;
  width: 100%;
  font-size: 20px;
}

.Sign_Up_Button:hover {
  background-color: #B8529E;
}
 
.FB_Icon {
  color: white;
  background-color: blue;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 4px;
}

.Register_Page_Laptop {
  height: 28rem;
  width: 21rem;
  margin-top: 12px;
}

.form-check {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.checkbox {
  font-size: 1rem;
}

.checkbox input[type="radio"] {
  margin-right: 10px;
}

.Signup_Instructor_Container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;
}

@media (max-width: 1102px) and (min-width: 760px) {
  .Register_Page_Laptop {
    height: 17rem;
    width: 29rem;
    margin-top: 6rem;
  }
}

.Sign_Up_Un {
  font-weight: bolder;
  text-shadow: 3px 3px 3px #959292;
  margin-top: 12rem;
  font-size: 3.4rem;
  line-height: 3.8rem;
}

@media (max-width: 1102px) and (min-width: 768px) {
  .Sign_Up_Un {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

@media (max-width: 768px) {
  .main_col2 {
    display: none;
  }
  .Full_Page_Img {
    background-image: none !important;
  }
}

.Input_Field_ {
  border: none;
  font-size: 0.8rem;
  width: 15rem;
  outline: none;
}

.logo_Register {
  width: 9rem;
  display: flex;
  justify-content: center;
}

.Already_Register {
  font-size: 1rem;
  display: flex;
  justify-content: center;
}

.By_Creating {
  display: flex;
  justify-content: center;
  font-size: 0.6rem;
}

body {
  background-color: #f8f9fa;
}

.form-control:focus, .form-control:active {
  border-color: #7D52A0;
  box-shadow: 0 0 0 0.2rem rgba(125, 82, 160, 0.25);
}

.btn-primary {
  background-color: #B8529E;
  border-color: #B8529E;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #8852A0;
  border-color: #8852A0;
}

.input-group-text {
  background-color: #3953A3;
  color: white;
  border-color: #3953A3;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.react-tel-input .selected-flag:hover, 
.react-tel-input .selected-flag:focus {
  background-color: #f1f1f1;
}

.react-tel-input .country-list {
  background-color: #ffffff;
  border-color: #ced4da;
}

.react-tel-input .country-list .country:hover {
  background-color: #f1f1f1;
}

.react-tel-input .country-list .country.highlight {
  background-color: #7D52A0;
}


