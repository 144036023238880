/* .Banner_Course_img {
  background-image: url("../Images/Banner_Course_img.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 0;
  height: 80vh;
} */ 

@media screen and (max-width: 460px) {
  .Banner_Course_img {
    height: 20vh;
  }
}
#ip {
  border-radius: 15px;
  /* border: 2px solid; */
  box-shadow: 2px 2px 10px;
  padding: 20px;
  height: 3px;
  width: 40%;
  margin-left: 26%;
}

.input-group-Course {
  margin: 30px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.form-control::-moz-placeholder {
  font-size: 22px;
}

.form-control::placeholder {
  font-size: 22px;
}

@media screen and (max-width: 460px) {
  #ip {
    border-radius: 25px;
    border: 2px solid;
    padding: 10px;
  }
  .input-group {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-control::-moz-placeholder {
    font-size: 7px;
  }
  .form-control::placeholder {
    font-size: 7px;
  }
}
.Catlog_Heading {
  display: flex;
  justify-content: center;
}

.Catlog_Text {
  display: flex;
  justify-content: center;
  color: #7D52A0;
}

/* .Skills_Text {
  line-height: 1.1;
  color: white;
  margin-top: 0px;
  font-size: 48px;
} */

hr.black_line {
  width: 40% !important;
  border: 2px solid rgb(0, 0, 0);
  text-align: center;
}

.filter li a {
  text-decoration: none;
  color: black;
}

.filter li a:hover {
  color: red;
}

/* .custom-dropdown-toggle {
  color: black;
} */

.list-group.freecard .list-group-item {
  background-image:  #E7C9FF;
  border-color: #dee2e6;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
}
.list-group.freecard .list-group-item:last-child {
  margin-bottom: 0;
}
.list-group.freecard .list-group-item h5 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.list-group.freecard .list-group-item p {
  font-size: 14px;
  margin-bottom: 0;
}
.list-group.freecard .list-group-item.small {
  padding: 5px;
}
.list-group.freecard .list-group-item.small h5 {
  font-size: 14px;
}
.list-group.freecard .list-group-item.small p {
  font-size: 12px;
}

.grid {
  text-align: center;
  border-radius: 3px;
  font-weight: bold;
  height: 40px;
  width: 48px;
  border: none;
  background-color: white;
  border: 2px grey solid;
}

.list-group {
  border: none;
}

.btn_list {
  list-style-type: none;
}

.homepagelink {
  text-decoration: none;
  color: black;
}

.homepagelink:hover {
  color: red;
}/*# sourceMappingURL=CoursePage.css.map */