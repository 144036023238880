.card.mb-2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
  border: none;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Adjusting card for smaller screens */
@media (max-width: 768px) {
  .card.mb-2 {
    flex-direction: column;
    margin: 10px auto;
    width: 95%;
  }
}

/* Image styles */
.card img {
  height: auto;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}

@media (max-width: 768px) {
  .card img {
    border-radius: 10px 10px 0 0;
  }
}

/* Card body styles */
.cat2card-body {
  padding: 15px;
  /* background: linear-gradient(135deg, #3953A3, #7D52A0); */
  background: #eee;
  color: white;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .cat2card-body {
    border-radius: 0 0 10px 10px;
  }
}

/* Text styling */
.cat2card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.card-description {
  font-size: 1rem;
  color: black;
  margin-bottom: 10px;
}

.card-text,
.card-date,
.card-instructor {
  font-size: 0.9rem;
  color: black;
  margin-bottom: 5px;
}
 
/* Action button container */
.cbutton {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  flex-wrap: nowrap; 
  gap: 10px; 
}
.btn-shared,
.btn-equal {
  flex: 1; /* Make buttons equal in width */
  text-align: center;
  margin: 0; /* Remove extra margins */
  padding: 10px 15px;
  border-radius: 25px;
  border: none;
  background-color: #B8529E;
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.btn-shared:hover,
.btn-equal:hover {
  background-color: #8852A0;
  transform: scale(1.05);
}

.btn-shared:active,
.btn-equal:active {
  transform: scale(0.95);
}

/* Adjust for responsive layouts */
@media (max-width: 576px) {
  .d-flex {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    align-items: stretch;
  }

  .btn-shared,
  .btn-equal {
    flex: none; /* Ensure buttons don't stretch */
    width: 100%; /* Make buttons full-width */
    margin-bottom: 10px; /* Add spacing between buttons */
  }
}
