/* .hover-card {
    transition: all 0.3s ease;
}
 
.hover-card:hover {
    transform: scale(1.05);
    box-shadow: inset 200px 0 0 0 red;
} */
 
.hover-card {
    position: relative;
    overflow: hidden; /* Ensures the pseudo-element stays within the card */
    transition: transform 0.3s ease;
    transition: color 0.1s ease;
}
 
.hover-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #E7C9FF; /* The fill color */
    transition: width 0.3s ease;
    z-index: 0; /* Ensure the overlay is behind the content */
}
 
.hover-card:hover {
    transform: scale(1.05);
    color: black;
}
 
.hover-card:hover::before {
    width: 100%; /* Fill the entire card */
}
 
.hover-card .card-body {
    position: relative;
    z-index: 1; /* Ensure content stays above the overlay */
}