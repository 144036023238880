/* Notification Toast Animation */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .notification-toast {
    animation: fadeInUp 0.5s ease-out;
    transition: transform 0.3s, background-color 0.3s;
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Hover effect */
  .notification-toast:hover {
    transform: scale(1.02);
    background-color: #f5f5f5;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Additional styling for Toasts */
  .toast-header {
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;
    color: #007bff;
  }
  
  .toast-body {
    font-size: 1rem;
    color: #333;
    line-height: 1.5;
  }
  
  