@media (max-width: 768px) {
  .nav-link {
    padding: 8px 10px;
    font-size: 14px;
  }

  .navbar-brand img {
    max-width: 120px;
  }
}
