/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
 
::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
 
a {
    text-decoration: none;
}
 
li {
    list-style: none;
}
 
h1 {
    font-weight: 600;
    font-size: 1.5rem;
}
 
body {
    font-family: 'Poppins', sans-serif;
}
 
.wrapper {
    display: flex;
}
 
.main {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    transition: all 0.35s ease-in-out;
    background-color: #fafbfe;
   
}
#sidebar{
    width: 70px;
    min-width: 70px;
    z-index: 1000;
    transition: all.25s ease-in-out;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
 
#sidebar.expand {
    width: 260px;
    min-width: 260px;
}
 
#toggle-btn {
    background-color: plum;
    cursor: pointer;
    border: 0;
    padding: 1rem 1.5rem;
}
 
#toggle-btn i {
    font-size: 1.5rem;
    color: #fff;
}
 
#sidebar-logo {
    margin: auto 8;
 }
 
 .sidebar-logo a {
    color: #FFF;
    font-size: 1.15rem;
    font-weight: 600;
}
 
#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span {
    display: none;
}
 
.sidebar-nav {
    padding: 2rem 0;
    flex: 1 1 auto;
    box-sizing: border-box;
   
}
 
a.sidebar-link {
    padding: .625rem 1.625rem;
    background-color:white ;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    border-left: 3px solid transparent;
}
 
.sidebar-link i {
    font-size: 1.1rem;
    margin-right: .75rem;
 
}
 
a.sidebar-link:hover {
    background:linear-gradient(to right, #3953A3, #7D52A0, #7D52A0, #B8529E) ;
    border-left: 3px solid #3b7ddd;
    border-radius: 20px;
}
 
.sidebar-item {
    border-radius: 25px;
    margin-bottom: 25px;
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    box-sizing: border-box;
    align-content: space-between;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to right, #3953A3, #7D52A0, #7D52A0, #B8529E) ;
 
}
 
#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
    position: absolute;
    top: 0;
    left: 70px;
    background-color: #3d0d74;
    padding: 0;
    min-width: 15rem;
    display: none;
    border-radius: 4px;
}
 
#sidebar:not(.expand) .sidebar-item:hover .has-dropdown+.sidebar-dropdown {
    display: block;
    max-height: 15em;
    width: 100%;
    opacity: 1;
}
 
#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
    border: solid;
    border-width: 0 .075rem .075rem 0;
    content: "";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    transform: rotate(-135deg);
    transition: all .2s ease-out;
}
 
#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
    transform: rotate(45deg);
    transition: all .2s ease-out;
}
.close-btn-symbol {
    font-size: 20px;
    color: #f8f5f5; 
    font-weight: bold;
  }
  
.icon-text {
 
    margin-left: 10px; 
    color: rgb(29, 28, 28);
    font-weight: bold;
 
}
  
.edit-con {
    z-index: 1000;
    position: absolute;
    left: 20rem;
    top: 10.6em;
    color:rgb(29, 28, 28);
 
  }
  .icon{
    color:rgb(29, 28, 28);
  }
  .textarea-custom-height {
    height: 38px; 
  }
  

.sidebar-content {
    background-color: red; 
    color: white;
  }
 
  
  .sidebar-nav a {
    color: rgb(29, 28, 28); 
  }
 
  .sidebar-nav a:hover {
    background-color:#0e2238; 
  } */



  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
 
::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
 
a {
    text-decoration: none;
}
 
li {
    list-style: none;
}
 
h1 {
    font-weight: 600;
    font-size: 1.5rem;
}
 
body {
    font-family: 'Poppins', sans-serif;
}
 
.wrapper {
    display: flex;
}
 
.main {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    transition: all 0.35s ease-in-out;
    background-color: #fafbfe;
   
}
#sidebar{
    width: 70px;
    min-width: 70px;
    z-index: 1000;
    transition: all.25s ease-in-out;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
 
#sidebar.expand {
    width: 260px;
    min-width: 260px;
}
 
#toggle-btn {
    background-color: black;
    cursor: pointer;
    border: 0;
    padding: 1rem 1.5rem;
}
 
#toggle-btn i {
    font-size: 1.5rem;
    color: black;
}
 
#sidebar-logo {
    margin: auto 2;
 }
 
 .sidebar-logo a {
    color: #FFF;
    font-size: 1.15rem;
    font-weight: 600;
}
 
#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span {
    display: none;
}
 
.sidebar-nav {
    padding: 0rem 0;
    flex: 1 1 auto;
    box-sizing: border-box;
    
}
 
a.sidebar-link {
    padding: .7rem 1rem;
    background-color:white ;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    /* border-left: 3px solid transparent; */
    border-radius: 5px;
    text-decoration: none;
}
 
.sidebar-link i {
    font-size: 1.1rem;
    margin-right: .75rem;
 
}
 
a.sidebar-link:hover {
    background:linear-gradient(to right, #7D52A0, #7D52A0, #B8529E) ;
    /* border-left: 3px solid; */
    /* border-radius: 5px; */
}
 
.sidebar-item {
    border-radius: 25px;
    margin-bottom: 25px;
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    box-sizing: border-box;
    align-content: space-between;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    background: linear-gradient(to right, #7D52A0, #7D52A0, #B8529E) ;
    border-radius: 5px;
 
}
 
#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
    position: absolute;
    top: 0;
    left: 70px;
    background-color: #3d0d74;
    padding: 0;
    min-width: 15rem;
    display: none;
    border-radius: 4px;
}
#sidebar:not(.expand) .sidebar-item:hover .icon-text {
    display: inline; /* Show the text on hover when the sidebar is collapsed */
    position: center;
    left: 20px; /* Adjust positioning to avoid overlap with icon */
    background-color: #0e2238; /* Optional: background for visibility */
    padding: 5px 10px;
    border-radius: 4px;
    color: white; /* Ensure the text is visible */
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown+.sidebar-dropdown {
    display: block;
    max-height: 15em;
    width: 100%;
    opacity: 1;
}
 
#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
    border: solid;
    border-width: 0 .075rem .075rem 0;
    content: "";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    transform: rotate(-135deg);
    transition: all .2s ease-out;
}
 
#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
    transform: rotate(45deg);
    transition: all .2s ease-out;
}
.close-btn-symbol {
    font-size: 20px;
    color: #f8f5f5; /* Adjust the color as needed */
    font-weight: bold;
  }
  /* Add this to your Sidebarr.css or your stylesheet */
.icon-text {
 
    margin-left: 10px; /* Adjust the margin as per your preference */
    color: rgb(29, 28, 28);
    font-weight: bold;
 
}
  /* Add this CSS to your stylesheet */
.edit-con {
    z-index: 1000;
    position: absolute;
    left: 20rem;
    top: 10.6em;
    color:rgb(29, 28, 28);
 
  }
  .icon{
    color:rgb(29, 28, 28);
  }
  .textarea-custom-height {
    height: 38px; /* Adjust as needed */
  }

  
  /* Add these styles to your CSS file or style section */
 
/* Style for the sidebar content */

 
  /* Style for the links inside the sidebar */
  .sidebar-nav a {
    color: rgb(29, 28, 28);; /* Set the link text color to white */
  }
 
  /* Hover effect for the links */
  .sidebar-nav a:hover {
    background-color:#0e2238; /* Add a dark background color on hover */
  }

  /* Sidebar hover effect */
.sidebar-item:hover .sidebar-link {
    color: white; /* Makes the link text white on hover */
  }
  
  .sidebar-item:hover .icon-text {
    color: white; /* Makes the icon text white on hover */
  }
  