/* Base Styles (applied across all screen sizes) */
.logo {
  width: 6rem; /* Reduced logo size */
  margin-left: 5px;
} 

/* .black_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; 
} */

.black_nav {
  position: fixed; /* Keep the header at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1050; /* Ensure it's above other content */
  background-color: #f8f9fa; /* Set a background color */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
}

.black_nav .nav-item,
.black_nav a {
  font-size: 14px; /* Slightly smaller font size */
  margin-left: 0;
}

.user_name_tooltip {
  position: absolute;
  background-color: #222;
  color: #f9f9f9;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.9em;
  top: 50px; /* Adjust position as needed */
  right: -10px; /* Adjust to align with icon */
  z-index: 1000;
  white-space: nowrap;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none; /* Prevents tooltip from blocking hover */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.nav_link:hover .user_name_tooltip {
  opacity: 1;
  transform: translateY(0);
}


.black_nav a {
  color: black;
  margin-inline: 8px;
  position: relative;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.black_nav a:hover {
  background-color: transparent;
}

.black_nav a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #807FFE;
  left: 50%;
  bottom: -3px;
  transition: width 0.3s ease, left 0.3s ease;
}

.black_nav a:hover::after {
  width: 100%;
  left: 0;
}

.navbar-toggler {
  background-color: rgba(107, 106, 106, 0.53);
  border: 1.4px solid;
  padding: 4px 8px; /* Reduced padding */
}

.nav_icons {
  width: 1rem;
  height: 1rem; /* Reduced size for icons */
}

.nav_user_i {
  width: 1.5rem;
  height: 1.5rem; /* Reduced size for user icon */
}

/* Responsive Styles */

/* For smaller screens: phones and tablets (max-width: 991px) */
@media screen and (max-width: 991px) {
  .logo {
    width: 5rem; /* Smaller logo for smaller screens */
  }

  .black_nav {
    flex-direction: column;
    padding: 0.3rem 1rem; /* Further reduced padding */
  }

  .nav-item {
    margin-bottom: 5px; /* Reduced spacing between items */
    text-align: center;
  }

  .black_nav a {
    margin-inline: 5px;
    font-size: 13px; /* Smaller font size */
  }

  .wishlist {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
  }

  .nav_user_img {
    width: 1.4rem;  /* Match the width */
    height: 1.4rem; /* Match the height */
    border-radius: 50%; /* Ensures the image looks like an icon */
    object-fit: cover; /* Ensures the image fills the element without distortion */
  }
  
  .nav_user_i {
    width: 1.4rem;
    height: 1.4rem;
  }

  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 0.6rem 1rem; /* Adjusted padding for navbar */
  }
}

/* For larger screens: laptops and desktops (min-width: 992px) */
@media screen and (min-width: 992px) {
  .logo {
    width: 8rem; /* Reduced size for large screens */
    margin-left: 10px;
  }

  .black_nav {
    justify-content: flex-start;
    padding: 0.5rem 1.5rem; /* Reduced padding */
  }

  .black_nav a {
    margin-inline: 12px;
    font-size: 14px;
  }

  .wishlist {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }

  .nav_user_i {
    width: 1.7rem;
    height: 1.7rem; /* Adjusted icon size */
  }
}
