.about-container {
    display: flex;       
    height: 100vh;       
  }
  
  .about-left, .about-right {
    flex: 1;             
    padding: 20px;
  }
  
  
  .about-right {
    
    background-color: white;
  }

  .about-left img {
    width: 50vw;        
    height: 100vh;        
    object-fit: cover;    /* Makes sure the image covers the area without stretching */
    position: absolute;   /* Positions it to take up the whole screen */
    top: 0;
    left: 0;
    border-radius: 30px;
    box-shadow: 10px 10px 20px rgba(255, 0, 0, 0.5);  /* Red shadow on the right and bottom */
  }
  
  .about-course{
    width: 563px;
  }
  .about-course:hover{
    background-color: red;
    border: 2px solid black;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    border-radius: 15px;
  }
  .about-containted{
    margin-left: 30px;
  }


  .about-certificat{
    width: 563px;
  }

  .about-certificat:hover{
    background-color: red;
  border: 2px solid black;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  border-radius: 15px;
  }
  .about-Learn{
    width: 563px;
  }
  .about-Learn:hover{
    background-color: red;
    border: 2px solid black;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    border-radius: 15px;
  }

  


.about-image-wrapper {
  position: relative;
  width: 100%; 
  height: 100%; 
}


.about-image-wrapper img {
  width: 100%; /* Makes the image fill the container */
  height: 100%; /* Makes the image fill the container */
  object-fit: cover; /* Ensures the image covers the container without distortion */
  transition: filter 0.3s ease; /* Smooth transition for the blur effect */
}

.about-image-wrapper:hover img {
  filter: blur(25px); /* Applies blur effect to the image on hover */
}

.about-overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
 
  transform: translate(-50%, -50%);
  color: black;
  font-weight: bold;
  font-size: 24px;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition for text appearance */
}

.about-image-wrapper:hover .about-overlay-text {
  opacity: 1; /* Shows the text when the image is hovered */
}


  
  
/*responsive desing*/

@media only screen and (min-width: 100px) and (max-width:300px){
  
 
    .about-container {
      display: grid;
      grid-template-rows: auto auto; /* Two rows: one for left, one for right */
      width: 100vw;
      height: 100vh;
    }
  
    .about-left {
      height: 100vh; /* Let it adapt to content */
      width: 100vw; /* Full width on mobile */
    }
  
    .about-right {
      width: 100vw;
      margin-top: 20px; /* Adds some spacing between the rows */
    }
  
    .about-course, .about-certificat, .about-Learn {
      width: 100vw; /* Ensures full width on small screens */
      display: block; /* Make sure they behave as block elements */
      margin-bottom: 10px; /* Optional: Adds spacing between sections */
    }
  
    .about-overlay-text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%; 
      height: auto;
      padding: 10px;
      font-size: 16px; /* Adjusted font size for mobile */
    }
    .about-containted{
      margin: auto auto;
    }
  }
  
@media only screen and (min-width: 300px) and (max-width:600px){
  
 
    .about-container {
      display: grid;
      grid-template-rows: auto auto; /* Two rows: one for left, one for right */
      width: 100vw;
      height: 100vh;
    }
  
    .about-left {
      height: 100vh; /* Let it adapt to content */
      width: 100vw; /* Full width on mobile */
    }
  
    .about-right {
      width: 100vw;
      margin-top: 20px; /* Adds some spacing between the rows */
    }
  
    .about-course, .about-certificat, .about-Learn {
      width: 100vw; /* Ensures full width on small screens */
      display: block; /* Make sure they behave as block elements */
      margin-bottom: 10px; /* Optional: Adds spacing between sections */
    }
  
    .about-overlay-text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%; 
      height: auto;
      padding: 10px;
      font-size: 16px; /* Adjusted font size for mobile */
    }

    .about-containted{
      margin: auto auto;
    }
  }
  
@media only screen and (min-width: 600px) and (max-width:1200px){
  
 
    .about-container {
      display: grid;
      grid-template-rows: auto auto; /* Two rows: one for left, one for right */
      width: 100vw;
      height: 100vh;
    }
  
    .about-left {
      height: 100vh; /* Let it adapt to content */
      width: 100vw; /* Full width on mobile */
    }
  
    .about-right {
      width: 100vw;
      margin-top: 20px; 
    }
  
    .about-course, .about-certificat, .about-Learn {
      width: 100vw; 
      display: block; 
      margin-bottom: 10px; 
    }
  
    .about-overlay-text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%; 
      height: auto;
      padding: 10px;
      font-size: 16px; 
    }

    .about-containted{
      margin: auto auto;
    }
  }
  



