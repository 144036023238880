.program-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  will-change: transform;
  background: rgba(76, 29, 149, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  overflow: hidden;
}

.program-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.program-card-img {
  transition: transform 0.3s ease;
}

.program-card:hover .program-card-img {
  transform: scale(1.05);
}

.program-card-body {
  background: rgba(76, 29, 149, 0.7);
  transition: background 0.3s ease;
}

.program-card:hover .program-card-body {
  background: rgba(76, 29, 149, 0.9);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}