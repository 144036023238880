/* body {
  font-family: "Poppins";
}

.footer {
  background-color: #211F1F;
  color: white;
}

.Logo5 {
  width: 7rem;
  height: 4rem;
  color: white;
}

.mail {
  font-size: small;
}

.icons li {
  list-style: none;
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 10%;
  align-items: center;
}

.socialcolor {
  color: white;
}

.links li {
  text-decoration: none;
  color: white;
  list-style: none;
}

.links li a {
  text-decoration: none;
  color: white;
  list-style: none;
}

.links li a:hover {
  color: red;
}

.Quick {
  font-size: medium;
  font-weight: bold;
}

.footericon {
  border-radius: 10px;
  height: 4rem;
  width: 4rem;
}

.storeimg {
  width: 105px;
  height: 28px;
  padding-inline: 5px;
}

.emailbtn {
  margin-right: 8px;
  background-color: red;
  color: white;
  border-top-left-radius: 0px 0px;
  border-bottom-left-radius: 0px 0px;
}

.copyrights {
  text-align: center;
}

.company {
  color: red;
}

.contact {
  font-size: small;
}

.contact:hover {
  color: red;
}

.form-control {
  border-top-right-radius: 0px 0px;
  border-bottom-right-radius: 0px 0px;
}# sourceMappingURL=Footer.css.map */

body {
  font-family: "Poppins";
}

.footer {
  background-color: #211F1F;
  color: white;
}

.Logo5 {
  width: 10rem;
  height: 4rem;
  color: white;
}

.mail {
  font-size: small;
}

.icons li {
  list-style: none;
  background-color: #b369ec;
  width: 25px;
  height: 25px;
  border-radius: 10%;
  align-items: center;
}

.socialcolor {
  color: white;
}

.links li {
  text-decoration: none;
  color: white;
  list-style: none;
}

.links li a {
  text-decoration: none;
  color: white;
  list-style: none;
}

.links li a:hover {
  color: #aa83ca;
}

.Quick {
  font-size: medium;
  font-weight: bold;
}

.footericon {
  border-radius: 10px;
  height: 4rem;
  width: 4rem;
}

.storeimg {
  width: 105px;
  height: 28px;
  padding-inline: 5px;
}

.emailbtn {
  margin-right: 8px;
  background-color: #7D52A0;
  color: white;
  border-top-left-radius: 0px 0px;
  border-bottom-left-radius: 0px 0px;
}

.copyrights {
  text-align: center;
}

.company {
  color:#b369ec;
}

.contact {
  font-size: small;
}

.contact:hover {
  color: #aa83ca;
}

.form-control {
  border-top-right-radius: 0px 0px;
  border-bottom-right-radius: 0px 0px;
}/*# sourceMappingURL=Footer.css.map */