/* Custom styles to match the design */
.form-control:focus,
.form-select:focus {
  border-color: transparent;
  box-shadow: none;
}

.card {
  border-radius: 8px;
}

.progress {
  height: 8px;
  border-radius: 4px;
}

.progress-bar {
  background-color: #0d6efd;
}

/* Nav tabs styling */
.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  border: none;
  color: #6c757d;
  padding: 0.75rem 1rem;
}

.nav-tabs .nav-link:hover {
  border: none;
  color: #0d6efd;
}

.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #0d6efd;
  color: #0d6efd;
}

/* Form switch custom styling */
.form-switch .form-check-input {
  width: 2.5em;
  margin-left: 0;
}

.form-switch .form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

/* Remove default button shadow */
.btn:focus {
  box-shadow: none;
}
