.Full_Page_Img{
    background-image: url('../Images/Full_Page_Img.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 0;
   //  height: 100vh;
    margin-top: 0px;
    // width: 100%;
} 
@media screen and ( max-width:1400px) and (min-width: 760px){
   .Full_Page_Img{
    height: unset;
   }
   }
   @media only screen and (max-width: 768px) {
      .main_col2{
       display: none;
      }
      .Full_Page_Img{
        // display: none; 
        background-image: none !important;
      }
  
  }
// @media screen and ( max-width:460px){
//   .Full_Page_Img{
//    height: 50vh;
//   }
//  }
 .Log_Text{
    font-weight: bold;
    margin-top: 12rem;
    font-size: 2.5rem;
    line-height: 3rem;
 }
 .Table_Girl_{
    margin-top: 10rem; ;
    height: 50%;
 }
 .btn-Sign-In {
   background-color: red;
   color: white;
   height: 8%;
   // border-radius: 10px;
   width: 100%;
   padding-top: 7px;
   padding-bottom: 7px;
   margin-bottom: 10px;
   border-top-left-radius: -10px 0px;
   border-bottom-left-radius: -10px 0px;
 }
 .btn-Sign-In:hover {
   background-color: red;
   color: white;
 } 
 .Register_Icons_div{
   display: flex;
  align-items: center;
  border-radius: 5px;
  // margin: 1.5rem;
  height: 2.5rem;
  border: 1px solid black;
 }
 .Login_to_Your_Account{
   font-size: 2rem;
 }

 .New_to_Kaas{
   display: flex;
   justify-content: center;
   font-size: 0.9rem; ;
 }
 .Privacy_Policy{
   display: flex;
   justify-content: center;
   font-size: 0.5rem;
 }
 .form-control::placeholder { 
  font-size: 14px; }