.hover-purple:hover {
  color: #9333ea;
}

.btn-purple {
  background-color: #9333ea;
  color: white;
}

.btn-purple:hover {
  background-color: #7e22ce;
  color: white;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-control:focus {
  background-color: transparent;
  color: white;
  border-color: #9333ea;
  box-shadow: 0 0 0 0.25rem rgba(147, 51, 234, 0.25);
}
