.Log_Text {
  font-weight: bold;
  margin-top: 12rem;
  font-size: 2.5rem;
  line-height: 3rem;
}

.Login_img {
  margin-top: 4rem;
  height: 50%;
  align-items: start;
}

/* .btn-Sign-In {
  background-color: #7D52A0;
  color: white;
  height: 8%;
  width: 70%;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  border-radius: 15px; 
  display: block;
  margin: 0 auto; 
} */

.Btn-Sign-In:hover {
  background-color: #B8529E;
  color: white;
}
 
.Register_Icons_div {
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 2.5rem;
  border: 1px solid black;
}

.Login_to_Your_Account {
  font-size: 2rem;
}

.New_to_Kaas {
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
}

.Privacy_Policy {
  display: flex;
  justify-content: center;
  font-size: 0.5rem;
}

.form-control::-moz-placeholder {
  font-size: 14px;
}

.form-control::placeholder {
  font-size: 14px;
}/*# sourceMappingURL=Log.css.map */