 .Full_Width_Container{
  // width: 100%;
 }
 .Banner_Course_img{
            background-image: url('../Images/Banner_Course_img.png');
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            z-index: 0;
            height: 80vh;
            // width: 100%;
            }
@media screen and ( max-width:460px){
   .Banner_Course_img{
    height: 20vh;
   }
  }
  
#ip{ 
  border-radius: 25px;
  border: 2px solid; 
  padding: 25px; 
  height: 4px; 
} 
.input-group-Course{
  margin-top: 40px;
  width: 100%;
  // height: 10px; 
  padding-left: 20px;
  padding-right:20px;
}  
.form-control::placeholder {
  font-size: 22px;
}   


@media screen and ( max-width:460px){
  #ip{
      border-radius: 25px;
      border: 2px solid; 
      padding: 10px; 
      // width: 70px;
      // height: 2px; 
    } 
    .input-group{
      width: 100%;
      // height: 10px; 
      padding-left: 20px;
      padding-right:20px;
    }  
    .form-control::placeholder {
      font-size: 12px;
    } 
 }
  
.Catlog_Heading{
    display: flex;
    justify-content: center;
}
.Catlog_Text{
    display: flex;
    justify-content: center;
    color: red;
}
.Skills_Text{
    line-height: 1.1;
    color: white;
    margin-top: 160px;
    font-size: 48px;
}
hr.black_line{
        // height: 3px  !important;
        width: 40%  !important;
        //border-bottom: 8px black;
        border: 2px solid rgb(0, 0, 0);
        // color: 5px black!important;
        text-align: center;
}

// .filter li{
//     list-style-type: none;
//     margin-right: 48px;
    
// }
.filter li a{
    text-decoration: none;
    color: black;
    // margin-right: 20px;

}
.filter li a:hover{
    color: red;
}
// .freecard{
//    padding-left: 40px;
//    padding-right: 40px;
// }
// .custom-dropdown-toggle {
//   color: black;
// }


.list-group {
    &.freecard {
      .list-group-item {
        background-color: #f8f9fa;
        border-color: #dee2e6;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
        width: 20px;
        height: 20px;
  
        &:last-child {
          margin-bottom: 0;
        }
  
        h5 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;
        }
  
        p {
          font-size: 14px;
          margin-bottom: 0;
        }
  
        &.small {
          padding: 5px;
  
          h5 {
            font-size: 14px;
          }
  
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
.grid{
   
    text-align: center;
    border-radius: 3px;
    font-weight: bold;
    height: 40px;
    width: 48px;
    border: none;
    background-color: white;
    border: 2px grey solid;
}
.list-group{
  border: none;
}
.btn_list{
  list-style-type: none;
}
.homepagelink{
  text-decoration: none;
  color: black;

}
.homepagelink:hover{
  color: red;
}
