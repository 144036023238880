.catalog-card {
  flex: 1 1 calc(25% - 20px); /* Default: 4 cards per row on large screens */
  margin: 10px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 435px;
}

.catalog-card:hover {
  transform: scale(1.02); /* Subtle scale effect on hover for better interactivity */
}

.catalog-image {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 0 0;
  height: 200px; /* Consistent height for better layout */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.catalog-image img {
  display: block;
  width: 100%;
  height: auto;
}

.catalog-content {
  padding: 20px;
  text-align: center;
  overflow: hidden;
}

.catalog-content h3 {
  font-size: 15px;
  margin-bottom: 10px;
  color: #333;
  /* text-overflow: ellipsis; */
  /* text-wrap: pretty; */
}

.catalog-content p {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.catalog-footer {
  display: flex;
  align-items: center;
  color: white;
}

.catalog-price {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.catalog-free {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.image-padding {
  margin: 10px;
}

.catalog-footer-main {
  width: 100%;
  background-color: #7D52A0;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  justify-content: center; /* Center align text */
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.catalog-footer-main:hover {
  background-color: #6a3e8a; 
  transform: scale(1.05); 
}

.wishlist-container {
  width: 100%;
  margin-top: 10px;
}

.wishlist-btn button {
  width: 100%;
  background-color: #7D52A0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.wishlist-btn button:hover {
  background-color: #6a3e8a; /* Same hover effect as the View Course button */
  transform: scale(1.05); /* Subtle zoom effect */
}

.wishlist-btn button:active {
  transform: scale(0.98); /* Slightly shrink when pressed */
}

.enroll-now {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.arrow img {
  margin-left: 8px;
  width: 14px;
  height: 14px;
  transition: transform 0.3s ease;
}

.arrow {
  color: #ddd;
  display: flex;
  justify-content: center;
}

.catalog-footer-main:hover .arrow img {
  transform: translateX(5px); /* Adds a sliding effect to the arrow */
}

.footermaincat {
  display: flex;
}

/* Large screens (laptops/desktops) */
@media (min-width: 1200px) {
  .catalog-card {
    flex: 1 1 calc(20% - 20px); /* 5 cards per row for larger screens */
  }
  .catalog-content h3 {
    font-size: 22px;
  }
  .catalog-content p {
    font-size: 15px;
  }
}

/* For tablets (2-3 cards per row) */
@media (max-width: 1024px) {
  .catalog-card {
    flex: 1 1 calc(33.333% - 20px); /* 3 cards per row */
  }
  .catalog-content h3 {
    font-size: 18px;
  }
}

/* For mobile screens (1 card per row) */
@media (max-width: 768px) {
  .catalog-card {
    flex: 1 1 100%; /* 1 card per row */
  }
  .catalog-content {
    padding: 15px;
  }
  .catalog-content h3 {
    font-size: 16px;
  }
  .catalog-content p {
    font-size: 13px;
  }
  .catalog-footer-main {
    padding: 10px;
  }
}
